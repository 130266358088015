export const form_online_mixin = {
    data() {
        return {}
    },
    watch: {
        //
    },
    computed: {
        //
    },
    methods: {
        //
    }
}