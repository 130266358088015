<template>
  <PageLoader color="#e0e0e0" min-height="100vh" v-if="loading"></PageLoader>
  <v-card
    v-else
    :elevation="mdAndUp ? 3 : 0"
    class="form-wrapper rounded-10"
    min-height="800"
  >
    <v-row align="stretch" dense>
      <v-col :cols="mdAndUp ? 8 : 12" class="mx-auto py-md-5 py-sm-0">
        <v-card :flat="smAndDown" min-height="500">
          <v-card-text>
            <v-list-item v-if="form">
              <v-list-item-avatar width="80" height="80" color="grey" tile>
                <v-img
                  contain
                  v-if="form.company_logo"
                  :src="form.company_logo"
                />
                <v-icon large v-else color="white">
                  mdi-alpha-c-circle-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="headline primary--text font-weight-bold"
                >
                  {{ form.title | ucwords }}
                </v-list-item-title>
                <v-list-item-subtitle class="primary--text">
                  {{ form.company.name | ucwords }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-sheet
              class="my-1 text-center pa-1"
              v-if="form.props.description"
            >
              <p>{{ form.props.description }}</p>
            </v-sheet>
            <v-alert
              outlined
              prominent
              type="error"
              v-if="requiredAuth && unauthenticated && !viewMode"
            >
              <v-row align="center">
                <v-col class="grow">
                  This form required the respondent to be logged in. Please
                  login to continue.
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    @click="login_and_redirect"
                    class="text-none pa-5"
                    depressed
                    dark
                    color="primary"
                  >
                    Login
                    <v-icon right>mdi-login </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-card-text>
          <v-card-text v-if="form && !submitted">
            <v-sheet
              class="mb-1"
              v-for="(line, index) in get_sections"
              :key="line.id"
            >
              <QuestionSection :value="index"></QuestionSection>
            </v-sheet>
          </v-card-text>
          <v-divider />
          <v-card-text class="pb-5" v-if="!submitted">
            <v-alert
              v-if="viewMode"
              class="text-center font-weight-bold"
              type="error"
              outlined
            >
              Cannot submit form in preview mode!
            </v-alert>
            <div
              :class="[mdAndUp ? 'justify-space-between' : 'flex-column']"
              class="d-flex fullwidth align-center"
            >
              <div
                :class="[
                  mdAndUp ? 'order-1' : 'order-3 justify-start fullwidth'
                ]"
                class="d-flex align-start flex-column"
              >
                <small class="primary--text fw-700">Powered by </small>
                <v-img
                  :lazy-src="settings.loader"
                  :width="100"
                  contain
                  :src="settings.longLogo"
                />
              </div>

              <div
                :class="[mdAndUp ? 'order-2' : 'order-1']"
                class="text-md-center primary--text d-flex flex-column align-center justify-center"
              >
                <v-chip
                  v-if="requiredAuth && !unauthenticated"
                  label
                  outlined
                  class="caption border-0 align-self-center"
                  style="border: none"
                >
                  <span>
                    Responding as
                    <strong class="primary--text">{{ user.fullname }} </strong>
                  </span>
                </v-chip>
                <v-chip
                  large
                  label
                  class="caption border-0 my-0"
                  outlined
                  style="border: none; display: block"
                >
                  <span>
                    Never submit passwords through
                    {{ settings.SiteName }} Forms.
                  </span>
                </v-chip>
              </div>

              <div
                :class="[mdAndUp ? 'order-3' : 'order-2']"
                class="d-flex align-center justify-center"
              >
                <v-btn
                  :block="smAndDown"
                  v-if="form && form.status === 'active'"
                  @click="submitForm"
                  x-large
                  depressed
                  color="primary"
                  :disabled="
                    viewMode ||
                    submitting ||
                    disabled ||
                    (unauthenticated && requiredAuth)
                  "
                  :loading="submitting"
                  class="px-10 text-none"
                >
                  Submit
                  <v-icon right>mdi-email-send </v-icon>
                </v-btn>
                <v-btn
                  :block="smAndDown"
                  v-else
                  x-large
                  depressed
                  disabled
                  class="px-5 text-none"
                >
                  No more response permitted
                </v-btn>
              </div>
            </div>
          </v-card-text>
          <v-card-text v-if="submitted" class="py-5 my-5">
            <Empty
              icon="mdi-check-circle-outline"
              headline="Your response has been recorded."
            >
              <template v-slot:extra>
                <v-btn
                  large
                  depressed
                  color="primary"
                  class="text-none px-10"
                  @click="submit_another"
                >
                  Submit another response
                </v-btn>
              </template>
            </Empty>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <pre>{{ get_showable_sections }}</pre> -->
  </v-card>
</template>

<script>
import request from '@/services/axios_instance'
import _ from 'lodash'
import { form_online_mixin } from './form_online_mixin'
import { mapGetters, mapMutations } from 'vuex'
import QuestionSection from '@/modules/Forms-Online/components/QuestionSection.vue'
export default {
  components: { QuestionSection },
  mixins: [form_online_mixin],
  data: () => ({
    form: null,
    loading: true,
    submitted: false,
    submitting: false
  }),
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('forms', [
      'structures',
      'get_watch_values',
      'get_sections',
      'get_showable_sections'
    ]),
    disabled() {
      return !this.validFormFillOut
    },
    unauthenticated() {
      return !this.user ? true : false
    },
    requiredAuth() {
      if (!this.form) return false
      return (
        this.form.props.required_auth &&
        this.form.props.required_auth.toLowerCase() === 'yes'
      )
    },
    viewMode() {
      return this.$route.params.hasOwnProperty('view') &&
        this.$route.params.view
        ? true
        : false
    },
    validFormFillOut() {
      if (this.get_showable_sections.length) {
        let hasFind = this.get_showable_sections.find((section) => {
          return section.value.find((i) => {
            if (
              i.hasOwnProperty('required') &&
              i.required &&
              (!i.value || i.value.length === 0)
            ) {
              return true
            } else return false
          })
        })
        return hasFind ? false : true
      } else return false
    }
  },
  watch: {
    '$route.params.slug': {
      handler: function (val) {
        if (val) this.getForm(val)
      },
      immediate: true
    },
    form: {
      handler: function (val) {
        if (val) {
          this.set_label_color(val.props.label_color)
          this.set_theme_color(val.props.theme_color)
        }
        this.set_structures(val ? val.questions : [])
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapMutations('forms', [
      'set_label_color',
      'set_theme_color',
      'set_structures'
    ]),
    getForm(slug) {
      this.loading = true
      request
        .get(`api/form/${slug}/online`)
        .then(({ data }) => {
          this.form = data
        })
        .catch((err) => {
          this.appSnackbar('Form not found', 'error')
          this.$router.push({ name: 'not_found' })
          throw err
        })
        .finally(() => (this.loading = false))
    },
    submitForm() {
      if (this.viewMode || !this.validFormFillOut) return
      this.submitting = true
      this.$store.commit('set_loader', true)
      let payload = { data: _.cloneDeep(this.get_showable_sections) }
      if (this.user) {
        payload.user_id = this.user.id
      }
      request
        .post(`api/form/${this.form.id}/online`, payload)
        .then(({ data }) => {
          this.submitted = true
        })
        .finally(() => {
          this.submitting = false
          this.$store.commit('set_loader', false)
        })
    },
    submit_another() {
      this.$router.go()
    },
    login_and_redirect() {
      this.$router.push({
        name: 'login',
        query: {
          redirect_uri: this.$route.fullPath
        }
      })
    }
  }
}
</script>

<style></style>
